import "./style.css"
import { useEffect } from 'react';
import { useInView } from "react-intersection-observer";

export default function Overview() {
 
  const [ref, inView] = useInView({
    /* Optional options */
    triggerOnce: false,
    rootMargin: '0px 0px',
    threshold: 0.9,
  })
  
  useEffect(() => {
    if (inView) window.location.replace('#Overview')

  }, [inView])
  return (
    <div ref={ref} id="Overview">
      <h1 className="title">Visão Geral do Livro</h1>
      <h2 className="subtitle">
        <span className="nobold">O livro tem 108 páginas e 5 Capítulos:</span></h2>
      <div className="chapter-container">
        <div className="chapter">
          <div className="chapterbox">
            <p className="chapter-number">Capítulo 1</p>
          </div>
          <p className="text">Introdução</p>
        </div>
        <div className="chapter">
          <div className="chapterbox">
            <p className="chapter-number">Capítulo 2</p>
          </div>
          <p className="text">Abstrações mentais e a construção do conhecimento musical</p>
        </div>
        <div className="chapter">
          <div className="chapterbox">
            <p className="chapter-number">Capítulo 3</p>
          </div>
          <p className="text">Percurso Metodológico</p>
        </div>
      </div>
      <div className="chapter-container">
        <div className="chapter">
          <div className="chapterbox">
            <p className="chapter-number">Capítulo 4</p>
          </div>
          <p className="text">Construção de conhecimento musical a partir de Piaget -
          uma revisão integrativa</p>
        </div>
        <div className="chapter">
          <div className="chapterbox">
            <p className="chapter-number">Capítulo 5</p>
          </div>
          <p className="text">Conclusões Parciais</p>
        </div>
      </div>
      <div className="review-container">
        <h1 className="review-title">Vídeos</h1>
        <div className="review-video-container">
          <video className="review-video" controls preload="auto"><source src="/videos/teaser_livro.mp4" type='video/mp4' /></video>
        </div>
        <div className="review-video-container">
          <video className="review-video" controls preload="auto"><source src="/videos/marketing.mp4" type='video/mp4' /></video>
        </div>
      </div>
      <div className="review-text-container">
      <h1 className="review-title">Depoimentos</h1>
      <div className="text-flexbox">
        <div className="text-content">
          <p className="text review"><q><i>Foi um prazer lhe rever Marcelinho!<br/>
          Uma honra poder adquirir um livro seu!</i></q></p>
          <p className="text review review-author"><i>- Jô</i></p>
        </div>
        <img className="review-image" src="/images/jo.jpg" alt="Foto de Jô recebendo o livro"/>
      </div>  
      </div>
    </div>
  )
}
